.nothing-here {
  text-align: center;
  padding: 20px;
  background-color: #f7f7f7;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

.nothing-here h1 {
  font-weight: bold;
  margin-top: 0;
  color: #337ab7;
}

.nothing-here p {
  font-size: 16px;
  color: #666;
}

.nothing-here a {
  text-decoration: none;
  color: #337ab7;
}

.nothing-here a:hover {
  color: #23527c;
}