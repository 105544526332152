.not-found-page {

    text-align: center;
    padding: 20px;
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
}
  
.not-found-page h1 {
    font-weight: bold;
    margin-top: 0;
}
  
.not-found-page p {
    font-size: 16px;
    color: #666;
}
  
.not-found-page a {
    text-decoration: none;
    color: #337ab7;
}
  
.not-found-page a:hover {
    color: #23527c;
}