* {
  box-sizing: border-box;
}
body {
  font-family: Arial, Helvetica, sans-serif;
}

#chatlog {
  position: absolute;
  bottom: 32px;
  left: 0;
  right: 300px;
  overflow-y: scroll;
  padding: 8px;
  height: 95%;
  align-content: end;
  z-index: -1;
}
#chatlog span.username {
  font-weight: bold;
}
#spacer {
  height: calc(100vh - 32px - 5em);
}

#roster {
  font-weight: bold;
  padding: 8px;
}

p {
  margin-top: 0;
  margin-bottom: 8px;
}
p:last-of-type {
  margin: 0;
}

#roster {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 32px;
  width: 300px;
  border-left: none;
}

@media(max-width:600px) {
  #roster { display: none; }
  #chatlog { right: 0; }
}

#chat-input {
  position: fixed;
  width: 100%;
  height: 32px;
  bottom: 0;
  left: 0;
  border: none;
  border-top: none;
  padding-left: 32px;
  outline: none;
}

#chat-actions {
  margin: 5px; 
}
#chat-actions button{
  margin: 5px; 
}

#chatroom::before {
  z-index: 1;
  display: block;
  content: ">";
  position: fixed;
  bottom: 0;
  left: 0;
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  font-weight: bold;
  color: #888;
  -webkit-text-stroke-width: 2px;
}

#name-form {
  position: fixed;
  z-index: 3;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
}

#name-input {
  position: fixed;
  font-size: 200%;
  top: calc(50% - 1em);
  left: calc(50% - 8em);
  width: 16em;
  height: 2em;
  margin: 0;
  text-align: center;
  border: 1px solid #bbb;
}

#name-form p {
  position: fixed;
  top: calc(50% + 3em);
  width: 100%;
  text-align: center;
}

#room-form {
  position: fixed;
  z-index: 2;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  font-size: 200%;
  margin-top: calc(50vh - 3em);
  text-align: center;
}

#room-name {
  font-size: inherit;
  border: 1px solid #bbb;
  height: 2em;
  width: 16em;
  padding-left: 1em;
}

#room-form button {
  font-size: inherit;
  border: 1px solid #bbb;
  background-color: #eee;
  height: 2em;
}

@media(max-width:660px) {
  #name-input, #room-form { font-size: 150%; }
  #name-form p { font-size: 75%; }
}
@media(max-width:500px) {
  #name-input, #room-form { font-size: 100%; }
  #name-form p { font-size: 50%; }
}

#go-public {
  width: 4em;
}
#go-private {
  width: 20em;
}
